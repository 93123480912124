import { reactive, type Component } from 'vue'

import TextSegment from './components/segments/TextSegment.vue'
import LinkSegment from './components/segments/LinkSegment.vue'
import InputSegment from './components/segments/InputSegment.vue'
import SelectSegment from './components/segments/SelectSegment.vue'
import ToggleSegment from './components/segments/ToggleSegment.vue'
import DateSegment from './components/segments/DateSegment.vue'

export type FilterType = 'unique' | 'fixed' | 'repeated' | 'headless'

export type FilterSegmentText = {
  type: 'text'
  default: string
}

export type FilterSegmentLink = {
  type: 'link'
  default: {
    url: string
    title: string
  }
}

export type FilterSegmentToggle = {
  id: string
  type: 'toggle'
  index: number
  placeholder: string
  default: string
  options: Array<{ key: string; value: string }>
  value: string | null
}

export type FilterSegmentSelect = {
  id: string
  type: 'select'
  index: number
  placeholder: string
  default: string | null
  options: Array<{
    key: string
    value: string
    disabled?: boolean
    title?: string
  }>
  value: string | null
  search?: boolean
}

export type FilterSegmentInput = {
  id: string
  type: 'input'
  index: number
  placeholder: string
  default: string | null
  empty: boolean
  value: string | null
}

export type FilterSegmentDate = {
  id: string
  type: 'date'
  index: number
  placeholder: string
  value: string | null
}

export type InteractiveSegment =
  | FilterSegmentToggle
  | FilterSegmentSelect
  | FilterSegmentInput
  | FilterSegmentDate
export type InformativeSegment = FilterSegmentText | FilterSegmentLink

export type FilterConfigSegment = InformativeSegment | InteractiveSegment

export type FilterSegmentType = FilterConfigSegment['type']

export function getSegmentComponent(type: FilterSegmentType): Component {
  switch (type) {
    case 'text':
      return TextSegment as Component
    case 'link':
      return LinkSegment as Component
    case 'input':
      return InputSegment as Component
    case 'select':
      return SelectSegment as Component
    case 'toggle':
      return ToggleSegment as Component
    case 'date':
      return DateSegment as Component
  }
}

export function isInteractiveSegment(
  segment: FilterConfigSegment
): segment is InteractiveSegment {
  return segment.type !== 'link' && segment.type !== 'text'
}

export type FilterConfigEntry = {
  key: string
  value: string
  type: FilterType
  require: string
  segments: Array<FilterConfigSegment>
  persisted: boolean
}

export type FilterEntry = {
  key: string
  values: Array<string | null>
}

export type FilterArray = Array<FilterEntry>

export type BadgeEmit = 'sort' | 'filter' | 'search'

export type BadgeConfig = {
  sort: Array<{ key: string; value: string }>
  filters: Array<FilterConfigEntry>
}

export type BadgeData = {
  sort: { asc: boolean; key: string }
  search: string
  filters: FilterArray
  freeze: boolean
}

export type BadgeBuilderItemSet = {
  val: string
  set: true
}

export type BadgeBuilderItemUnset = {
  val: string | null
  set: false
}

export type BadgeBuilderItem = BadgeBuilderItemSet | BadgeBuilderItemUnset

export function isBadgeBuilderItemSet(
  item: BadgeBuilderItem
): item is BadgeBuilderItemSet {
  return item.set
}

export type BadgeBuilderData = {
  key: BadgeBuilderItem
  segments: Array<BadgeBuilderItem>
  current: null | number
}

export function reactiveData(): BadgeData {
  return reactive<BadgeData>({
    sort: { asc: false, key: '' },
    filters: [],
    search: '',
    freeze: false
  })
}

export function encodeValues(values: Array<null | string>) {
  return values.map((value) => btoa(encodeURIComponent(value ?? '')))
}

export function decodeUTF8Base64(string: string) {
  return new TextDecoder().decode(
    Uint8Array.from(atob(string), (c) => c.charCodeAt(0))
  )
}

export function decodeValues(values: Array<string>) {
  return values.map((value) => {
    try {
      return decodeURIComponent(decodeUTF8Base64(value))
    } catch (e: unknown) {
      console.info(`Tried to decode invalid base64 value '${value}'`, e)
      return null
    }
  })
}

export function encodeFilters(filters: FilterArray): string {
  return filters.reduce((acc: string, { key, values }: FilterEntry) => {
    return `${acc}${acc.length > 0 ? '+' : ''}${key}&${encodeValues(
      values
    ).join('&')}`
  }, '')
}

export function decodeFilters(
  string: string,
  dataConfig: BadgeConfig
): FilterArray {
  const filters: FilterArray = []

  for (const token of string.split('+')) {
    const [key, ...values] = token.split('&')
    const config = dataConfig.filters.find(({ key: _key }) => _key === key)

    if (config) {
      filters.push({
        key,
        values: decodeValues(
          values.slice(0, config.segments.filter(isInteractiveSegment).length)
        )
      })
    }
  }

  return filters
}
