<template>
  <div class="flex items-center text-sm pointer-events-none bg-gray-200 px-2">
    <span v-text="value" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  value: string
}>()

const value = computed(() => props.value)
</script>
