<template>
  <a
    class="flex items-center text-sm px-2 bg-gray-200 hover:bg-gray-300 cursor-pointer"
    :href="value.url"
  >
    <span v-text="value.title" />
  </a>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  value: {
    url: string
    title: string
  }
}>()

const value = computed(() => props.value)
</script>
