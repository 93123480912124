<template>
  <button
    ref="wrapperElement"
    class="relative px-2 badge-segment bg-gray-200 min-w-[26px]"
    :tabindex="isEditable ? '0' : '-1'"
    :class="{
      'pointer-events-none': !isEditable || isOpen,
      'bg-gray-300': isOpen,
      'bg-gray-200 hover:bg-gray-300 cursor-pointer': isEditable && !isOpen,
      'min-w-[120px]': isOpen
    }"
    @focusout="hideOptions"
    @click="showOptions"
  >
    <span class="text-sm" v-text="displayValue" />
    <div v-show="isOpen" ref="popupElement" class="fixed z-[1001]">
      <div
        class="pointer-events-auto bg-white list flex flex-col whitespace-nowrap"
      >
        <input
          ref="inputElement"
          type="date"
          tabindex="0"
          class="text-sm px-2 py-1"
          :placeholder="placeholder"
          :value="value"
          :aria-label="placeholder"
          spellcheck="false"
          @keydown.enter="(event) => setValue(event, event.target.value)"
          @change="(event) => setValue(event, event.target.value)"
        />
      </div>
    </div>
  </button>
</template>

<script lang="ts" setup>
import { computed, ref, watch, nextTick, onMounted } from 'vue'
import { type FilterSegmentDate } from '../../shared'

const props = defineProps<{
  value: string | null
  segment: FilterSegmentDate
  setup?: boolean
  edit?: boolean
}>()

const emit = defineEmits<{
  (e: 'change', value: string): void
  (e: 'open'): void
  (e: 'close'): void
}>()

const open = ref(false)
const wrapperElement = ref<HTMLDivElement>()
const inputElement = ref<HTMLInputElement>()
const popupElement = ref<HTMLElement>()
const parentElement = computed(() =>
  wrapperElement.value?.closest('.badge-container')
)

const value = computed(() => props.value)
const placeholder = computed(() => props.segment.placeholder)

const isEditable = computed(() => props.edit)
const isOpen = computed(() => props.setup || (props.edit && open.value))
const isEmpty = computed(
  () => typeof value.value === 'undefined' || value.value === null
)

watch(
  () => props.setup,
  (newValue, oldValue) => {
    if (oldValue && !newValue) {
      open.value = false
    }
  }
)

const displayValue = computed(() => {
  if (isEmpty.value) {
    return props.segment.placeholder
  } else {
    const date = new Date(props.value)

    if (date) {
      return date.toLocaleString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    } else {
      return props.value
    }
  }
})

const setValue = (event: Event, val: string) => {
  event.preventDefault()
  event.stopPropagation()

  if (val) {
    open.value = false

    emit('change', val)
  }
}

const showOptions = () => {
  if (!isEditable.value) return

  open.value = true
}

const hideOptions = (e: FocusEvent) => {
  if (!isEditable.value) return
  const isFocusOnChildren = !!wrapperElement.value?.contains(
    e.relatedTarget as HTMLElement
  )
  open.value = isFocusOnChildren
}

const updatePopupPosition = () => {
  if (isOpen.value && wrapperElement.value) {
    const wrapper = wrapperElement.value as HTMLElement
    const wrapperRect = wrapper.getBoundingClientRect()

    const parent = parentElement.value as HTMLElement
    const parentRect = parent.getBoundingClientRect()

    const element = popupElement.value as HTMLElement
    element.style.left = `${Math.min(
      parentRect.right - element.clientWidth,
      Math.max(parentRect.left, wrapperRect.left)
    )}px`
    element.style.top = `${wrapperRect.top + 32}px`

    window.requestAnimationFrame(() => updatePopupPosition())
  }
}

const onSegmentStateChange = (open: boolean) => {
  if (!open) return

  // Focus input on next tick
  nextTick(() => inputElement.value.focus())

  const wrapper = wrapperElement.value as HTMLElement
  wrapper.scrollIntoView({ inline: 'center', block: 'nearest' })

  window.requestAnimationFrame(() => updatePopupPosition())
}

watch(isOpen, (value: boolean) => onSegmentStateChange(value))
onMounted(() => onSegmentStateChange(props.setup))

// Emit open event if segment was opened
watch(open, (value: boolean) => {
  if (value) {
    emit('open')
  } else {
    emit('close')
  }
})
</script>
